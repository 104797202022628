import { pathJoin } from '@pretto/bricks/core/utility/path/pathJoin'

import PropTypes from 'prop-types'

import * as S from '../styles'

const Image = ({ description, full, path = '', slug = '', ...props }) => (
  <S.BlockLarge>
    <S.Image alt={description} {...props} path={`website/content/${pathJoin(path, slug)}`} $isFull={!!full} />
  </S.BlockLarge>
)

Image.propTypes = {
  description: PropTypes.string,
  full: PropTypes.bool,
  path: PropTypes.string,
  slug: PropTypes.string,
}

export default Image
